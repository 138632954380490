.counter-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
    padding: 20px 0;
  }
  
  .counter-item,
    .counter-item img {
    text-align: center;
    background: var(--primary-color);
    width: 100%;
    max-width: 300px;
  }
  
  .counter-item h1 {
    font-size: 3rem;
    color: var(--bg3);
    margin: 0;
  }

  .counter-item.fig{
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .counter-item p {
    font-size: 1.2rem;
    color: var(--bg3);
    margin: 5px 0 0;
  }

  @media screen and (max-width: 768px) {
    .counter-item {
        max-width: 90%;
    }

    .counter-item img{
        max-width: 100%;
    }
  }
  