.facility-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .facil{
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.3px solid #ddd;
  }

  .facility-section {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    position: relative;
  }
  
  .facility-image {
    flex: 1;
    overflow: hidden;
    position: relative;
    border-radius: 50% 50% 0 0;
  }
  
  .facility-image img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    transition: transform 0.2s ease-out; /* Smooth transition for the scroll effect */
    will-change: transform; /* Optimize for performance */
  }
  
  .facility-content {
    flex: 1;
    padding-right : 25px;
    padding-left: 50px;
    color: #555;
  }
  
  .facility-content h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .facility-content p {
    font-size: 1rem;
    line-height: 1.6;
  }

  @media screen and (max-width: 768px) {
    .facility-section {
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
  }
  