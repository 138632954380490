.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 900px;
    margin: auto;
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    position: relative;
    flex: 0 0 100%;
  }
  
  .carousel-item img {
    width: 100%;
    height: 70vh;
  }
  
  .carousel-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(0, 0, 0, 0.5); 
    padding: 20px;
    max-width: 80%;
  }
  
  .carousel-container .carousel-title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
  }
  
  .carousel-container .carousel-description {
    color: white;
    font-size: 16px;
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-color);
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
  }

  
    .carousel-control-next{
        border-radius: 50%  0 0 50%;
        right: 0;

    }
    .carousel-control-prev{
        border-radius:0 50% 50% 0;
        left: 0;
    }
  
  .carousel-control-prev.disabled,
  .carousel-control-next.disabled {
    opacity: 0;
    pointer-events: none;
  }
  
  
  