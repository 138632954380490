.service-detail {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    align-items: center;
    background: var(--bg3);
    border: 1px solid #ddd;
    
  }
  
  .service-detail-details, .service-detail{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .service-box, .service-box-box{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service-box-box{
    width: 60%;
    margin: 20px;
    flex-direction: column;
  }

  .service-detail .primary-image {
    width: 80%;
    max-height: 60vh;
    margin-bottom: 16px;
  }
  .secondary-images img:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  .service-detail-details{
    width: 40%;
    align-items: start;
    /* background: #f2efef; */
    padding: 20px;
  }

  .service-icons-box{
    display: flex;
    flex-wrap: wrap;
  }

  .service-icons-box p{
    margin:0 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .service-icons-box p span{
    color: var(--primary-color);
    margin-right: 10px;
    font-size: 16px;
  }

  .service-detail-details .price{
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .service-detail-details p {
    font-size: 16px;
    text-align: start;
    max-width: 90%;
    color: #333;
    margin-bottom: 22px;
    word-spacing: 0.15rem;
  }
  
  .service-detail p strong {
    color: var(--primary-color);
  }

  .aditional{
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
  }

  .aditional p{
    font-size: 14px;
    color: #555;
    line-height: 5px;
  }
  .navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .prev-btn,
  .next-btn {
    padding: 8px 16px;
    margin: 0 5px;
    background: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .prev-btn:hover,
  .next-btn:hover {
    background: #555;
  }
  
  .aditional p span{
    font-size: 14px;
    color: var(--primary-color);
    margin-right: 20px;
  }

  .aditional strong{
    color: var(--primary-color);
  }
  
  @media screen and (max-width: 768px) {
    .service-box, .service-box-box{
      flex-direction: column;
      width: 95%;
    }

    .service-detail .primary-image{
      width: 100%;
      height: auto;
    }

    .service-detail-details{
      width: 90%;
      align-items: start;
    }
  }