
  
  .admin-dashboard h2 {
    text-align: center;
    color: #4CAF50;
    margin-bottom: 1rem;
  }

  .admin-dashboard-controls{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 20px 0;
    background: var(--bg-color-even);
  }
  
  /* Add Service Button */
  .toggle-add{
    display: block;
    margin: 0 auto 1rem;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggle-add:hover {
    background-color: #45a049;
  }

  .search-bar{
    display: flex;align-items: center;
    width: 90%;
    padding: 10px;
    border-radius: 10px 0 0 10px;
  }

  .search-bar-icon{
    padding: 11px;
    background: #000;
    color: #fff;
    border-radius: 0 10px 10px 0;

  }
  
  /* Service Items */
  .admin-service-item {
    background: #f9f9f9;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .admin-service-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .admin-service-item h3 {
    margin: 0 0 0.5rem;
    font-size: 1.2rem;
    color: #333;
  }
  
  .admin-service-item p {
    margin: 0.5rem 0;
    color: #666;
  }
  
  /* Buttons */
  .admin-service-item button {
    padding: 8px 16px;
    margin-right: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .admin-service-item button:first-of-type {
    background-color: #ffc107;
    color: #fff;
  }
  
  .admin-service-item button:first-of-type:hover {
    background-color: #e0a800;
  }
  
  .admin-service-item button:last-of-type {
    background-color: #f44336;
    color: #fff;
  }
  
  .admin-service-item button:last-of-type:hover {
    background-color: #d32f2f;
  }
  
.service-actions {
  display: flex;
  gap: 10px;
}

.notification {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
}

.no-services {
  text-align: center;
  color: #666;
}
  
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .admin-service-item {
      padding: 0.8rem;
    }
  
    .toggle-add-hero-btn {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  
    .admin-service-item h3 {
      font-size: 1rem;
    }
  
    .admin-service-item p {
      font-size: 0.9rem;
    }
  
    .admin-service-item button {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  