.instagram-container {
    background: var(--bg3);
    padding: 40px 0;
}

.instagram-container h3{
    color: var(--primary-color);
}

.instagram-container h2{
  font-size: 26px;
}

.inst-con{
    padding: 40px 0;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    align-content: center;
}

.inst-box {
    position: relative;
    overflow: hidden;
    height: 250px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .inst-box:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  .inst-box:hover .overlay{
    display: none;
  }

.inst-box img{
    width: 100%;
    height: 100%;
    
}

.inst-box img:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 600px){
    .inst-con{
      grid-template-columns: repeat(2, 1fr);
    }

    .inst-box{
      height: 200px;
    }
  }