/* General Styles */
.explore-now-container {
    background: var(--bg3);
  }
  
  .explore-header {
    margin-bottom: 40px;
    padding: 20px;
  }
  
  .explore-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #555;
    margin-bottom: 10px;
    animation: fadeIn 1.2s ease-out;
  }
  
  .explore-subtitle {
    font-size: 1rem;
    color: #6c757d;
    max-width: 700px;
    margin: 0 auto;
    animation: fadeIn 1.5s ease-out;
  }
  
  /* Cards Section */
  .explore-cards-section {
    width: 90%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .explore-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 280px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .explore-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
  }
  
  .card-content {
    animation: fadeInUp 0.8s ease-out;
    color: #555;
    font-size: 0.9rem;
  }
  
  .card-button {
    background: var(--primary-color);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .card-button:hover {
    background-color: #706f67;
  }
  
  /* Testimonials Section */
  .testimonials-section {
    background-color: #343a40;
    color: #ffffff;
    padding: 40px 20px;
    margin-top: 40px;
    border-radius: 8px;
  }
  
  .testimonials-section h2 {
    margin-bottom: 20px;
  }
  
  .testimonial-card {
    background-color: #495057;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 15px;
    animation: fadeIn 1.2s ease-out;
  }
  
  /* Call to Action Section */
  .explore-cta-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 40px 0;
    padding: 40px 0;
    background: var(--primary-color);
    color: #ffffff;
    border-radius: 8px;
    text-align: center;
  }
  
  .explore-cta-section h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    animation: fadeIn 1.2s ease-out;
  }

  .explore-cta-section p{
    width: 80%;
  }
  
  .cta-button {
    background-color: var(--bg3);
    border: none;
    padding: 12px 25px;
    border-radius: 5px;
    color: #343a40;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #ddd;
  }
  
  /* Animations */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .explore-cards-section {
      flex-direction: column;
    }
  }
  