/* Services.css */

.services {
    background: var(--bg3);
  }
  
  .services h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .services-container {
    width: 92%;
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .service-card {
    display: flex;align-items: center;
    flex-direction: column;
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  .service-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .service-card p {
    color: #545454;
    font-size: 14px;
    width: 85%;
  }
  