.service-display{
  background: var(--bg3);
}
.service-list {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px 0;
    font-family: Arial, sans-serif;
  }
  
  .service-item {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .service-item:hover {
    transform: translateY(-5px);
  }
  
  .service-item-div{
    width: 97%;
    position: relative;
  }

  .service-item-div .price{
    background: var(--primary-color);
    color: #fff;
    margin:10px 25%;
    padding: 5px;
    border-radius: 20px;
  }

  .service-item h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .service-item p {
    margin: 5px 0;
    font-size: 0.85rem;
    color: #555;
  }
  
  .primary-image {
    width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .primary-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-controls button {
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    color: #333;
    border-radius: 4px;
  }
  
  .pagination-controls button.active {
    background-color: var(--primary-color);
    color: white;
  }
  
  .pagination-controls button:disabled {
    cursor: not-allowed;
    background-color: #ccc;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .service-list {
      gap: 15px;
    }
  
    .service-item h2 {
      font-size: 1.3rem;
    }
  
    .service-item p {
      font-size: 0.9rem;
    }
   
  }
  
  @media (max-width: 480px) {
    .service-item {
      padding: 15px;
    }
  
    .service-item h3 {
      font-size: 1.2rem;
    }
  
    .service-item p {
      font-size: 0.85rem;
    }
  
   
  }
  