/* Hero.css */
  .hero-container {
    position: relative;
    height: 90vh;
    width: 100%;
    background: url(/public/images/bed.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 50px;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(var(--bg-gradient-1)); */
    background: var(--bg-fade);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fadeIn 2s ease-in-out;
  }
  
  .hero-content {
    text-align: center;
    color: #fff;
    z-index: 2;
    animation: slideIn 1s ease-in-out;
  }

  .hero-p{
    color: var(--primary-color);
    font-size: small;
  }
  
  .hero-title {
    font-size: 3rem;
    margin-bottom: 1rem;
    animation: bounceIn 1.5s;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    animation: fadeIn 2s ease-in-out;
  }
  
  .hero-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: var(--primary-color);
    /* background-color: #ff7f50; */
    border: none;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    animation: fadeInUp 2.5s;
  }
  
  .hero-button:hover {
    background-color: grey;
    transform: scale(1.1);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2.5rem;
    }
  
    .hero-subtitle {
      font-size: 1.2rem;
    }
  
    .hero-button {
      padding: 0.6rem 1rem;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {

    .hero-container{
      height: 80vh;
    }
    
    .hero-title {
      font-size: 2rem;
    }
  
  }
  
  /* Keyframes for animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes bounceIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    80% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(30px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  