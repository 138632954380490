
.luxury-advert-container {
    padding: 40px 0;
    background: var(--bg3);

  }
  
  /* Advert Section Styles */
  .advert-section {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 1.2s ease-in-out;
  }
  
  /* Reverse Layout for Alternating Sections */
  .advert-reverse{
    flex-direction: row;
  }
  
  /* Image Styling */
  .advert-image {
    flex: 0 0 300px;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .advert-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
  }
  
  .advert-image img:hover {
    transform: scale(1.1);
  }
  
  /* Content Styling */
  .advert-content {
    flex: 0 0 500px;
    padding: 30px;
    background: linear-gradient(145deg, #ffffff, #f5f5f5);
    border-radius: 10px;
    animation: slideIn 1.2s ease-in-out;
  }
  
  /* Title Styling */
  .advert-title {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #333;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Text Styling */
  .advert-text {
    font-size: 1.1rem;
    line-height: 1.7;
    color: #666;
    font-family: 'Lora', serif;
  }
  
  /* Responsive Styles */
  
  /* Medium Screens */
  @media (max-width: 768px) {
    .advert-section {
      flex-direction: column;
    }
  
    .advert-reverse {
      flex-direction: column-reverse;
    }
  
    .advert-image,
    .advert-content {
      flex: 1;
      max-width: 100%;
    }
  }
  
  /* Small Screens */
  @media (max-width: 480px) {
    .advert-title {
      font-size: 1.5rem;
    }
  
    .advert-text {
      font-size: 1rem;
    }
  }
  
  /* Animations */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  