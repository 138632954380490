:root {
  --body-font-family: "DM Sans", sans-serif;
  --heading-font-family: "Playfair Display", serif;
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --body-font-bold: 600;
  --text-color: #5d666f;
  --link-color: #fd413b;
  --link: #1DC6DF;
  --bg: rgb(238, 171, 48);
  --bg2:#fca44a ;
  --bg3: #fff5ed;
  --family: 'Cinzel Decorative', cursive;
  --family2:"Raleway",sans-serif;
  --primary-color: #ab8965;
  --primary-color-rgb: 171, 137, 101;
  --line:linear-gradient(90deg, #1DC6DF 0%, #1DC6DF 50%, #fca44a 50%, #fca44a 100%);
}

* {
  --title-font: "Marcellus", Helvetica, Arial, sans-serif;
  --body-font-size: 16px;
  --title-font-weight: 300;
  --title-font-color: #181818;
  --body-font: "Jost", Helvetica, Arial, sans-serif;
  --mainmenu-font: "Jost", Helvetica, Arial, sans-serif;
  --mainmenu-font-weight: 400;
  --border-default: solid 1px rgba(30, 30, 30, 1);
  --bg-color-even: #E8E8E8;
  --bg-color-odd: #F4F4F4;
  --bg-dark-1: #181818;
  --bg-dark-1-rgb: 23, 23, 23;
  --bg-dark-2: #232323;
  --bg-dark-3: #303030;
  --bg-grey: #F0F1F3;
  --bg-light: #fff5ed;
  --bg-fade: rgba(0,0,0,.7);
  --bg-gradient-1: 0deg, rgba(var(--primary-color-rgb), .1) 0%, rgba(var(--primary-color-rgb), .2) 100%;
  --body-font: #606060;
  --light-font: rgba(255, 255, 255, .75);
  --swiper-theme-color: var(--primary-color);
  --rounded-1: 12px;
  --mega-menu-rounded: 0;
}

.App {
  text-align: center;
}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth; 
}

h1, h2, h3 {
  font-family: var(--title-font);
}

p{
  line-height: 1.5rem;
  font-family: var(--body-font);
}

/* dynamic styling of pages */
.contact, .instagram-container,.services, .about-us,.add-service,.auth-container, .admin-dashboard,.luxury-advert-container,.service-display, .service-display, .explore-now-container
{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  background: #f9f9f9;
  text-align: center;
}

.about-hero {
  position: relative;
  width: 100%;
  height: 55vh;
  background: url(../public/images/bg.png) no-repeat center center/cover;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 2s ease-in-out;
}

.hero-heading {
  font-size: 2.5rem;
  max-width: 90%;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #fff;
  border-bottom: 0.3px solid var(--primary-color);
}

.hero-subheading {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0 auto;
  width: 60%;
  color: var(--light-font); /* Orange color */
}

.btn3,.back-link, .back-link a{
  color: #fff;
  text-decoration: none;
  margin:2px 8px;
  text-align: center;
}

.scroll-to-top {
  position: fixed;
  bottom: 70px;
  right: 10px;
  background: var(--primary-color);
  border: 1px solid white;
  color: white;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0.9;
  z-index: 4000;
}

.scroll-to-top:hover {
  opacity: 1;
}

li{
  list-style: none;
}

.watsap{
  position: fixed;
  background: white;
  color: rgb(119, 246, 119);
  bottom: 10px;
  right: 8px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 50px;
  z-index: 4000;
  filter: drop-shadow(2px 7px 10px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .hero-subheading {
    width: 90%;
  }
}