
.contact {
    background: var(--bg3);
  }
  
  .contact h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .contact-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-btn {
    padding: 0.75rem;
    font-size: 1rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-btn:hover {
    background-color: #555;
  }
  
  .status-message {
    margin-top: 1rem;
    font-size: 1rem;
    color: green;
  }
  
  .map-container {
    margin-top: 2rem;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  /* Contact Details Section */
.contact-details {
  flex: 1 1 45%;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-details h3 {
  margin-bottom: 10px;
  color: #333;
}

.contact-details p {
  margin-bottom: 10px;
  line-height: 1.6;
  color: #555;
  font-size: 0.9rem;
}

/* Social Media Links */
.social-media {
  margin-top: 20px;
}

.social-media h3 {
  margin-bottom: 10px;
  color: #333;
}

.social-media a {
  display: block;
  margin-bottom: 5px;
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s;
}

.social-media a:hover {
  color: #666;
}

.social-links{
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-links a{
  margin: 10px;
}

/* Map Section */
.map-container {
  flex: 1 1 100%;
  margin-top: 20px;
}
  
  @media (max-width: 768px) {
    .contact-container {
      padding: 0 1rem;
      justify-content: center;
    }
  
    .contact-form {
      padding: 1.5rem;
    }
  }
  