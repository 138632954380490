.room-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 0;
    background-color: var(--bg3);
    margin: 20px 0;
  }
  
  .room-details h2 {
    margin-bottom: 10px;
    color: #333;
  }

  
  
  .amenities-section ul,
  .features-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .amenities-section ul li,
  .features-section ul li {
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
    color: #555;
  }

  .amenities-section .icon{
    color: var(--primary-color);
    margin-right: 20px;
  }
  
  .amenities-section ul li:last-child,
  .features-section ul li:last-child {
    border-bottom: none;
  }
  
  .features-section ul li strong {
    color: #333;
  }
  
  @media screen and (max-width: 768px) {
    .room-details{
      flex-direction: column;
    }
  }