/* Preloader styles */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bg3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .preloader h1{
    font-size: 50px;
    font-weight: 900;
    color: #555;
  }

  .preloader .loader {
    display: flex;
    align-items: center;
  }
  
  .preloader .loader .dot {
    width: 12px;
    height: 12px;
    margin: 5px;
    background: var(--primary-color);
    border-radius: 50%;
    animation: bounce 1.2s infinite;
  }
  
  .preloader .loader .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .preloader .loader .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .preloader .loader span {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #555;
    font-weight: bold;
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  