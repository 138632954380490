/* Auth.css */

.auth-container {
    background: var(--primary-color);
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
  }
  
  .auth-form {
    background: var(--bg3);
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
  }
  
  .book-f {
    text-align: center;
    margin-bottom: 20px;
    color: var(--bg3);
  }
  
  .auth-form input {
    width: 100%;
    padding: 12px 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .auth-form input:focus {
    border-color: #6b73ff;
    outline: none;
  }
  
  .auth-form button {
    width: 100%;
    background: var(--primary-color);
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .auth-form button:hover {
    background: #777880;
  }
  
  .auth-form .error-message {
    color: #d9534f;
    background: #f9d6d5;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .auth-container {
      padding: 20px 0 ;
    }
  
    .auth-form {
      padding: 20px;
    }
  }
  