/* General Styles */
.footer {
  background-color: var(--bg-dark-1);
  color: #ffffff;
  padding: 40px 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin-bottom: 30px;
}

/* Section Titles */
.footer-section h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: var(--primary-color);
}

/* About Section */
.about p {
  font-size: 1rem;
  line-height: 1.6;
  color: #d3d3d3;
  margin-bottom: 10px;
}

/* Quick Links */
.links ul {
  list-style-type: none;
  padding: 0;
}

.links ul li {
  margin-bottom: 10px;
}

/* Styling Link components directly */
.links ul li .footer-link {
  color: #d3d3d3;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.links ul li .footer-link:hover {
  color: var(--primary-color);
}

/* Contact Section */
.cont p {
  font-size: 1rem;
  color: #d3d3d3;
  margin: 5px 0;
}

.footer-social-links{
  display: flex;
  justify-content: center;
}

.footer-social-links a{
  color: var(--primary-color);
  margin: 5px 8px;
}


/* Footer Bottom */
.footer-bottom {
  margin-top: 30px;
  border-top: 1px solid #444;
  padding-top: 20px;
}

.footer-bottom p {
  font-size: 0.9rem;
  color: #a0a0a0;
}

/* Responsive Styles */

/* Medium Screen Adjustments (Tablets) */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin-bottom: 20px;
  }

  .footer-section h2 {
    font-size: 1.4rem;
  }
}

/* Small Screen Adjustments (Mobile) */
@media (max-width: 480px) {
  .footer {
    padding: 30px 15px;
  }

  .footer-section h2 {
    font-size: 1.2rem;
  }

  .footer-bottom p {
    font-size: 0.8rem;
  }

  .links ul li .footer-link {
    font-size: 0.95rem;
  }
}
