/* AboutUs.css */

.about-us {
    background: var(--bg3);
  }
  
  .about-us-container {
    width: 90%;
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }
  
  .about-us-text {
    flex: auto;
    width: 60%;
    text-align: left;
    color: #333;
  }
  
  .about-us-text p {
    margin: 1rem;
    text-align: left;
    line-height: 1.6;
    color: #555;
  }
  
  .about-us-image img {
    width: 80%;
  }
  
  @media (max-width: 768px) {
    .about-us-container {
      flex-direction: column;
      text-align: center;
    }
  
    .about-us-image {
      margin-top: 2rem;
    }

    .about-us-image img {
      width: 80%;
    }

    .about-us-text{
      width: 95%;
      line-height: 30px;
    }
  }
  