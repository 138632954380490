.add-service {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .add-service h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .add-service form {
    width: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .add-service input,
  .add-service textarea,
  .add-service select,
  .add-service button {
    padding: 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .add-service textarea {
    resize: none;
    height: 100px;
  }
  
  .add-service input:focus,
  .add-service textarea:focus,
  .add-service select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .add-service button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  .add-service button:hover {
    background-color: #0056b3;
  }
  
  .add-service button:active {
    transform: scale(0.98);
  }
  
  .add-service input::placeholder,
  .add-service textarea::placeholder {
    font-style: italic;
    color: #888;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .add-service {
      padding: 15px 0;
    }
  
    .add-service h2 {
      font-size: 1.8rem;
    }
  
    .add-service input,
    .add-service textarea,
    .add-service select,
    .add-service button {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .add-service h2 {
      font-size: 1.5rem;
    }
  
    .add-service input,
    .add-service textarea,
    .add-service select,
    .add-service button {
      font-size: 0.8rem;
    }
  }
  